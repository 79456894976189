<template>
  <div class="community">
    <div class="content">
      <img
        class="background-image"
        loading="lazy"
        width="1185"
        height="230"
        src="~/assets/imgs/home/invest-ad.webp"
        alt=""
      />
      <div class="title">Une communauté d’investisseurs immobiliers</div>
      <div class="subtitle">Trouvez les réponses à vos questions ou partagez votre expérience.</div>
      <a
        href="https://communaute.horiz.io/t/bienvenue-dans-la-communaute-horiz-io/46"
        target="_blank"
        @click="tracking"
      >
        <uikit-button type="secondary">Découvrir la communauté Horiz.io</uikit-button>
      </a>
    </div>
  </div>
</template>

<script setup>
import { UikitButton } from '@hz/ui-kit'

const tracking = () => useTracking().track('[HP] Clic CTA', { destination: 'communauté' })
</script>

<style lang="less" scoped>
.community {
  padding: 30px;

  .content {
    border-radius: 12px;
    max-width: 1240px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--ds-color-white-100);
    padding: 32px 6px;
    position: relative;
    overflow: hidden;

    .background-image {
      position: absolute;
      height: 100%;
      top: 0;
      right: -176px;
      z-index: -1;
      object-fit: cover;
    }

    .title {
      text-align: center;
      font-weight: var(--ds-weight-semi-bold);
      font-size: 24px;
    }

    .subtitle {
      text-align: center;
      font-size: var(--ds-font-md);
      margin-top: 12px;
      margin-bottom: 32px;
    }
  }

  @media @bp-desktop {
    padding: 30px 80px;

    .content {
      padding: 32px 32px 42px;

      .background-image {
        right: 0;
        width: 100%;
      }

      .title {
        font-size: 32px;
      }

      .subtitle {
        margin-top: 6px;
        font-size: 18px;
      }
    }
  }
}
</style>
